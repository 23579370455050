<template>
  <div class="shopAttention">
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="finishedText"
      @load="moreOnLoad"
    >
      <ul class="list">
        <li
          class="item"
          v-for="item in followShopData"
          :key="item.ShopId"
          @click="getShopId(item.ShopId)"
        >
          <figure class="shopLogo">
            <van-image
              class="shopLogoIcon"
              :src="item.ShopHeadPhoto && imgUrlFilter(item.ShopHeadPhoto)"
            >
              <template v-slot:error>
                <img
                  class="ImgIcon"
                  src="~assets/img/userManage/img_logo@2x.png"
                />
              </template>
            </van-image>
          </figure>
          <p class="shopName">{{ item.ShopName }}</p>
          <figure class="delLogo" @click.stop="del(item)">
            <img
              class="delLogoIcon"
              src="~assets/img/footprint/icon_list_delete@2x.png"
            />
          </figure>
        </li>
      </ul>
      <placeholder v-if="isPlaceholder"></placeholder>
    </van-list>
  </div>
</template>
<script>
import Vue from "vue";
import { List } from "vant";
import Placeholder from "@/components/common/Placeholder";
Vue.use(List);
export default {
  name: "ShopAttention",
  components: {
    Placeholder,
  },
  data() {
    return {
      pageIndex: 1,
      followShopData: [],
      finished: false,
      loading: true,
      isPlaceholder: false,
    };
  },
  computed: {
    finishedText() {
      if (this.isPlaceholder) {
        return "";
      } else {
        return this.$t.noMoreData;
      }
    },
  },
  mounted() {
    // 获取数据
    this.followShopByPage();
  },
  methods: {
    // 跳转商铺
    getShopId(ShopId) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "store",
        name: "store",
        query: [
          {
            name: "ShopId",
            value: ShopId,
          },
        ],
      });
    },
    imgUrlFilter(value) {
      // console.log(value.split("|")[0]);
      return this.$api.formatImageUrl(value);
    },
    // 获取关注商品数
    followShopByPage() {
      this.$commonMethod.showLoading();
      let param = {
        PageIndex: this.pageIndex,
      };
      this.$api.follow
        .followShopByPage(param)
        .then((res) => {
          this.loading = false;
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.isPlaceholder = true;
          } else {
            this.followShopData = data;
            this.isPlaceholder = false;
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
    // 加载更多
    moreOnLoad() {
      let param = {
        PageIndex: this.pageIndex + 1,
      };
      this.$api.follow
        .followShopByPage(param)
        .then((res) => {
          const data = res.data;
          if (data == null || data === "" || data.length === 0) {
            this.loading = false;
            this.finished = true;
          } else {
            this.followShopData = this.followShopData.concat(data);
            this.loading = false;
          }
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 删除关注
    del(item) {
      this.$commonMethod.dialogConfirm(
        this.$t.unfollow + item.ShopName,
        (_) => {
          this.addFollowShop(item);
        },
        (_) => {}
      );
    },
    // 取消获取关注
    addFollowShop(item) {
      this.$commonMethod.showLoading();
      let param = {
        ShopId: item.ShopId,
        IsFollow: 0,
      };
      this.$api.follow
        .addFollowShop(param)
        .then((res) => {
          this.$commonMethod.hideLoading();
          this.$commonMethod.showToast({
            type: "success",
            titleMsg: res.message,
          });
          setTimeout((_) => {
            let newList = [...this.followShopData];
            this.followShopData = newList.filter((newItem) => {
              if (newItem.ShopId !== item.ShopId) {
                return newItem;
              }
            });
          }, 800);
        })
        .catch((error) => {
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
      // console.log("这是isfirst" + this.isfirst);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin liItem {
  align-items: center;
  -webkit-align-items: center;
  background-color: #ffffff;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 17px 0 17px 15px;
  margin-top: 15px;
  border-radius: 8px;
  @include publicFlex;
}
.shopAttention {
  width: 100%;
  .list {
    margin: 0 auto;
    list-style: none;
    width: 92%;

    .item {
      min-height: 70px;

      @include liItem;
      .shopLogo {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 15px;
        margin-left: 0px;
        @include publicFlex;
        .shopLogoIcon {
          width: 3.5rem;
          height: 3.5rem;
          object-fit: cover;
          -webkit-object-fit: cover;
          border-radius: 100%;
          .ImgIcon {
            width: 3.5rem;
            height: 3.5rem;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__img {
            width: 3.5rem;
            height: 3.5rem;
            position: relative;
            object-fit: cover;
            border-radius: 100%;
          }
          ::v-deep .van-image__error {
            width: 3.5rem;
            height: 3.5rem;
            position: relative;
            object-fit: cover;
            border-radius: 100%;
          }
        }
      }
      .shopName {
        flex: 1;
        -webkit-flex: 1;
        font-size: 14px;
        color: #000000;
        font-weight: bold;
        text-align: left;
      }
      .delLogo {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        width: 70px;
        margin: 0;
        @include publicFlex;
        .delLogoIcon {
          width: 25px;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
